import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/client',
        name: 'client',
        component: () => import('./pages/Client.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('./pages/User.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/piece-pour-devis',
        name: 'piece-pour-devis',
        component: () => import('./pages/Piece.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/roles',
        name: 'role',
        component: () => import('./pages/Role.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/typeparc',
        name: 'typeparc',
        component: () => import('./pages/TypeParc.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/besoinpiece',
        name: 'besoinpiece',
        component: () => import('./pages/Besoin.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/configuration',
        name: 'configuration',
        component: () => import('./pages/Configuration.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/prime',
        name: 'prime',
        component: () => import('./pages/Prime.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/prix-revenu',
        name: 'prix-revenu',
        component: () => import('./pages/PrixRevenu.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/sav',
        name: 'sav',
        component: () => import('./pages/Sav.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./pages/Calendar.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/libre',
        name: 'libre',
        component: () => import('./pages/Libre.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/type-contrat',
        name: 'type-contrat',
        component: () => import('./pages/TypeContrat.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/editprofile',
        name: 'editprofile',
        component: () => import('./pages/EditProfile.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/parc',
        name: 'parc',
        component: () => import('./pages/Parc.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/panne',
        name: 'panne',
        component: () => import('./pages/Panne.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/reclamation',
        name: 'reclamation',
        component: () => import('./pages/Reclamation.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    {
        path: '/intervention',
        name: 'intervention',
        component: () => import('./pages/Intervention.vue'),
        beforeEnter: (to, form, next) => {

            if (localStorage.token == '' || localStorage.token == null) {
                return next({ name: 'login' })
            } else {
                next()
            }
        }
    },
    // {
    //     "refBonIntervention":"R1234",
    //     "refIntervention":"INT00000002"
    // }
    // {
    //     path: '/intervention',
    //     name: 'intervention',
    //     component: () => import('./pages/Intervention.vue'),
    //     beforeEnter: (to, form, next) => {

    //         if (localStorage.token == '' || localStorage.token == null) {
    //             return next({ name: 'login' })
    //         } else {
    //             next()
    //         }
    //     }
    // },

    // {
    //     path: '/formlayout',
    //     name: 'formlayout',
    //     component: () => import('./components/FormLayoutDemo.vue'),
    //     beforeEnter: (to, form, next) => {

    //         if (localStorage.token == '' || localStorage.token == null) {
    //             return next({ name: 'login' })
    //         } else {
    //             next()
    //         }
    //     }
    // },
    // {
    //     path: '/input',
    //     name: 'input',
    //     component: () => import('./components/InputDemo.vue'),

    // },
    // {
    //     path: '/floatlabel',
    //     name: 'floatlabel',
    //     component: () => import('./components/FloatLabelDemo.vue')
    // },
    // {
    //     path: '/invalidstate',
    //     name: 'invalidstate',
    //     component: () => import('./components/InvalidStateDemo.vue')
    // },
    // {
    //     path: '/button',
    //     name: 'button',
    //     component: () => import('./components/ButtonDemo.vue')
    // },
    // {
    //     path: '/table',
    //     name: 'table',
    //     component: () => import('./components/TableDemo.vue')
    // },
    // {
    //     path: '/list',
    //     name: 'list',
    //     component: () => import('./components/ListDemo.vue')
    // },
    // {
    //     path: '/tree',
    //     name: 'tree',
    //     component: () => import('./components/TreeDemo.vue')
    // },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue')
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlayDemo.vue')
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue')
    },
    {
        path: '/menu',
        component: () => import('./components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue')
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue')
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue')
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue')
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue')
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue')
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartDemo.vue')
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue')
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue')
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue')
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue')
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue')
    },
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('./components/BlocksDemo.vue')
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./components/IconsDemo.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
