<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <!-- <AppConfig
      :layoutMode="layoutMode"
      @layout-change="onLayoutChange"
      @change-theme="changeTheme"
    /> -->
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import axios from "axios";
export default {
  emits: ["change-theme"],
  data() {
    return {
      permissionNames: [],
      user: {},
      token: "",
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Tableau de bord",
          items: [
            {
              label: "ACCUEIL",
              icon: "home.svg",
              to: "/",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    logout() {
      axios.post("logout").then((response) => {
        console.log(response);
        localStorage.removeItem("permissionNames");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.permissionNames = [];
        this.user = {};
        this.token = "";
      });
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
    changeTheme(event) {
      this.$emit("change-theme", event);
    },
  },

  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme ? "images/logo.jpeg" : "images/logo.jpeg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    // AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
  mounted() {
    if (localStorage.token) {
      this.permissionNames = JSON.parse(localStorage.permissionNames);
      this.user = JSON.parse(localStorage.user);
      this.token = localStorage.token;
      console.log(this.token);
      if (this.permissionNames.includes("show client")) {
        this.menu[0].items.push({
          label: "Clients",
          icon: "client.svg",
          to: "/client",
        });
      }
      if (this.permissionNames.includes("show users")) {
        this.menu[0].items.push({
          label: "Utilisateurs",
          icon: "/utilsateurs.svg",
          to: "/user",
        });
      }
      if (this.permissionNames.includes("show reclamation")) {
        this.menu[0].items.push({
          label: "Réclamations",
          icon: "/info.svg",
          to: "/reclamation",
        });
      }
      if (this.permissionNames.includes("show intervention")) {
        this.menu[0].items.push({
          label: "Interventions",
          icon: "/repair.svg",
          to: "/intervention",
        });
      }
      if (this.permissionNames.includes("show needPiece")) {
        this.menu[0].items.push({
          label: "Pièces pour devis",
          icon: "/light.svg",
          to: "/piece-pour-devis",
        });
      }
      if (this.permissionNames.includes("gestion roles")) {
        this.menu[0].items.push({
        label: "Gestion des roles",
        icon: "gestionroll.svg",
        to: "/roles",
      });
      }
      this.menu[0].items.push({
        label: "Paramètres de profil",
        icon: "Setting.svg",
        to: "/editprofile",
      });
      
      
      if (this.permissionNames.includes("Gestion commande")) {
        this.menu[0].items.push({
        label: "Commande Libre",
        icon: "light.svg",
        to: "/libre",
      });
      }
      if (this.permissionNames.includes("Gestion database")) {
        this.menu[0].items.push({
          label: "Besoin piece",
          icon: "/besoinP.svg",
          to: "/besoinpiece",
        });
      }
      if (this.permissionNames.includes("Gestion database")) {
        let obj = { label: "base de données", items: [] };

        obj.items.push({
          label: "Parc chariot",
          icon: "/chariot.svg",
          to: "/parc",
        });
        obj.items.push({
          label: "type parc",
          icon: "/chariot.svg",
          to: "/typeparc",
        });
        obj.items.push({ label: "Type de pannes", icon: "/panne.svg", to: "/panne" });
        obj.items.push({
          label: "Type contart",
          icon: "/stock.svg",
          to: "/type-contrat",
        });
        obj.items.push({
          label: "Configurateur",
          icon: "/panne.svg",
          to: "/configuration",
        });
        this.menu.push(obj);
      }
      if (this.permissionNames.includes("Gestion database")) {
        let obj = { label: "STATISTIQUES", items: [] };

        obj.items.push({
          label: "Prime",
          icon: "/prime.svg",
          to: "/prime",
        });
        obj.items.push({
          label: "SAV",
          icon: "/menu_task.svg",
          to: "/sav",
        });

        obj.items.push({
          label: "Prix de revient",
          icon: "/prix-revenu.svg",
          to: "/prix-revenu",
        });

        this.menu.push(obj);
      }

      axios.defaults.headers.common["Authorization"] = "Bearer" + this.token;
    }
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
